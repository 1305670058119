import call from "@kinetics254/cassandra-base/service/http";
import constants from "./constants";
import store from "../../../store";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    reopenRejectedDocument({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      const { namespace, action, ...restPayload } = payload;
      call("post", constants.reopenRejectedDocument, restPayload)
        .then((res) => {
          Event.$emit("ApiSuccess", "Document re-opened successsfully!");
          commit("loader/SET_LOADING", false, { root: true });
          store.dispatch(`${namespace}/${action}`);
        })
        .catch((error) => {
          console.log(error);
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
