import store from "../../../store";

/**
 * Generic Class for opening rejected documents:
 */
export default class Document {
  loading = false;

  /**
   *
   * @param {*} docNo  Document number for the item  to be opened.
   * @param {*} provider Sigma provider to or base api that house the wrapper
   * @param {*} entity  Sigma actual api that perform the document reopening
   * @param {*} namespace This is the vuex module store namespace for tht the document reopened belongs to
   * @param {*} action  The vuex module store action responsible for loading a fresh list of the documents
   */
  constructor(docNo, provider, entity, namespace, action) {
    this.docNo = docNo;
    this.provider = provider;
    this.entity = entity;
    this.namespace = namespace;
    this.action = action;

    // CALL THE REOPEN DOCUMENT FOR NOW
    this.reopen();
  }
  reopen() {
    console.log("REOPENED");
    this.loading = true;
    store.dispatch("Models/reopenRejectedDocument", {
      docNo: this.docNo,
      provider: this.provider,
      entity: this.entity,
      namespace: this.namespace,
      action: this.action,
    });
  }
}
